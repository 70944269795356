<template>
  <v-container>
  <v-card>
    <v-card-title style="word-break: normal">
        <p class="text-h4">
          Immer noch und besonders jetzt - kein Grund, damit aufzuhören: Am Sonntag, 26.05.2024 findet der 13. Lauf gegen Rechts statt
        </p>
    </v-card-title>
    <v-card-text>
      <p class="text-h5" v-if="this.$store.state.raceOpen == false">Die Anmeldung ist geschlossen. Wir sehen uns 2025</p>
      <v-btn x-large v-if="this.$store.state.raceOpen">
        <router-link to="registration" style="text-decoration: none; color: inherit;">Hier anmelden</router-link>
      </v-btn>

      <p class="text-body-1">
        Mit zunehmender Besorgnis beobachten wir, dass in vielen Ländern Menschen vermehrt rechtskonservative Parteien in die Regierung und somit Nationalismus anstelle von Solidarität wählen. 
      </p>
      <p class="text-body-1">
        Rechte, rassistische und antisemitische Erzählungen spalten Gesellschaften und fördern gezielt Gewalt und Hass gegen Jüdinnen*Juden, Schwarze Menschen/People of Colour, Migrant*innen oder Rom*nja und Sinti*zze. 
        Rassistische und antisemitische Ansichten sind auch in Deutschland weitverbreitet. Kämpfe gegen Antisemitismus und Rassismus werden gegeneinander ausgespielt. 
        Nicht nur die AfD, sondern auch etablierte Parteien nutzen den Kampf gegen Antisemitismus als Vorwand, um rassistische Stereotype zu reproduzieren und eine schärfere Abschiebepolitik zu fordern. 
        In Hamburg verbreitet die AfD gezielt Falschinformationen und schürt so eine menschfeindliche Stimmung, die auch von den etablierten Parteien aufgegriffen wird und in der Verschärfung der Asyl- und Abschiebepolitik mündet. 
      </p>
      <p class="text-body-1">
        Mit den bevorstehenden Landtagswahlen in Thüringen besteht die Gefahr, dass ein Faschist als Spitzenkandidat der AfD gewählt wird und erneut in den Landtag einzieht.
        Daher laufen wir am 26.05.2024 wieder gegen Rechts und… 
      </p>
      <p class="text-body-1">
        … gegen jeden Antisemitismus und Rassismus.
      </p>
      <p class="text-body-1">
        … gegen Sexismus, Antifeminismus, Homo- und Transphobie und Ableismus.
      </p>
      <p class="text-body-1">
        … gegen Faschismus und ein Wiedererstarken faschistischer Parteien in Deutschland.
      </p>
      <p class="text-body-1">
        … für Solidarität und gegen Nationalismus.
      </p>
      <p class="text-body-1">
        … für eine offene, tolerante, diverse und moderne Gesellschaft.
      </p>
      <p class="text-body-1">
        … für den sozialen Zusammenhalt und für gesellschaftliche Teilhabe
      </p>
      <p class="text-body-1">
        #leavenoonebehind #refugeeswelcome #klarekantegegenrechts #noafd
      </p>
      <p class="text-body-1">
      Eure FC St. Pauli Marathonabteilung
      </p>
          <v-card>
          <v-card-item>
            <v-card-title>Daten</v-card-title>
          <v-list density="compact">
            <v-list-item>30.4. Bestellschluss für Shirts</v-list-item>
            <v-list-item>8.5. Das Geld für Shirts muss bei uns eingegangen sein</v-list-item>
            <v-list-item>12.5. Versand der Shirts</v-list-item>
            <v-list-item>13.5. Anmeldeschluss (Nachmeldungen vor Ort sind möglich)</v-list-item>
          </v-list>
          </v-card-item>
          </v-card>
    </v-card-text>
    <v-card-actions>
      <v-btn x-large v-if="this.$store.state.raceOpen">
        <router-link to="registration" style="text-decoration: none; color: inherit;">Hier anmelden</router-link>
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "RaceStatus",
  data: () => ({
    raceInfo: {}
  }),
  mounted: function () {
    this.getRace()
  },
  methods: {
    getRace() {
      const url = `${this.$base_url}/race/`

      axios.get(url)
          .then((response) => {
            this.raceInfo = response.data
            this.$store.commit('setRaceStatus', response.data.isOpen)
            this.$store.commit('setOnSite', response.data.onSiteEnabled)
            this.$store.commit('setShirtsEnabled', response.data.shirtsEnabled)
          })
    }
  }
}

</script>

<style scoped>

</style>
